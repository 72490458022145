<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="header-logo" v-if="showMenu">
        <router-link to="/">
          <img
            alt="Logo"
            :src="logoLight"
            class="logo-default max-h-50px"
          />
          <img
            alt="Logo"
            :src="logoDark"
            class="logo-sticky max-h-40px"
          />
        </router-link>
      </div>
      <div class="header-logo mx-auto" v-else>
        <img
          alt="Logo"
          :src="logoLight"
          class="logo-default max-h-40px"
        />
        <img
          alt="Logo"
          :src="logoDark"
          class="logo-sticky max-h-40px"
        />
      </div>
      <div class="d-flex align-items-stretch mr-3">
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <KTMenu v-if="showMenu"></KTMenu>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <div class="topbar" v-if="showMenu">
          <KTLanguageBar></KTLanguageBar>
          <KTUserbar class="ml-5" v-if="myInfo"></KTUserbar>
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTUserbar from "@/view/layout/header/Userbar.vue";
import KTLanguageBar from "@/view/layout/header/LanguageBar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";

import logoDark from '@/assets/logo/logo_dark.png';
import logoLight from '@/assets/logo/logo_light.png';

export default {
  name: 'KTHeader',
  components: {
    KTUserbar,
    KTLanguageBar,
    KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    myInfo() {
      return this.$store.state.myInfo;
    },
    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  props: {
    showMenu: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      logoDark,
      logoLight
    }
  }
};
</script>
