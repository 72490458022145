<template>
  <!--begin: User bar -->
  <div class="topbar-item">
    <b-dropdown
      ref="dropdown"
      size="sm"
      variant="link"
      toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <img class="circle-image-30" :src="`${myInfo.photoUrl ? myInfo.photoUrl : assets.profile}`" />
      </template>
      <b-dropdown-text tag="div" class="min-w-md-200px">
        <ul class="navi navi-hover">
          <li class="navi-item">
            <span class="pointer navi-link" @click="gotoProfile()">
              <i class="ml-1 fa fa-user color-purple"></i>
              <span class="navi-text ml-5">{{ $t('profile') }}</span>
            </span>
          </li>
        </ul>
        <ul class="navi navi-hover" v-if="myInfo.userType === USER_ORGANIZER">
          <li class="navi-item">
            <span class="pointer navi-link" @click="gotoMyEvents()">
              <i class="ml-1 flaticon-event-calendar-symbol color-purple"></i>
              <span class="navi-text ml-4">{{ $t('my_events') }}</span>
            </span>
          </li>
        </ul>
        <ul class="navi navi-hover" v-else>
          <li class="navi-item">
            <span class="pointer navi-link" @click="gotoMyTickets()">
              <i class="ml-1 fa fa-ticket-alt color-purple"></i>
              <span class="navi-text ml-3">{{ $t('my_tickets') }}</span>
            </span>
          </li>
        </ul>
        <ul class="navi navi-hover" v-if="myInfo.userType === USER_ORGANIZER">
          <li class="navi-item">
            <span class="pointer navi-link" @click="gotoAddEvent()">
              <i class="ml-1 fa fa-calendar-plus color-purple"></i>
              <span class="navi-text ml-5">{{ $t('add_event') }}</span>
            </span>
          </li>
        </ul>
        <b-dropdown-divider></b-dropdown-divider>
        <ul class="navi navi-hover">
          <li class="navi-item">
            <span class="pointer navi-link" @click="doLogout()">
              <i class="ml-1 flaticon-logout color-purple"></i>
              <span class="navi-text ml-4">{{ $t('logout') }}</span>
            </span>
          </li>
        </ul>
      </b-dropdown-text>
    </b-dropdown>
  </div>
  <!--end: User bar -->
</template>

<script>
import profile from '@/assets/images/profile.png';

export default {
  name: 'UserBar',
  computed: {
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  data() {
    return {
      assets: {
        profile
      }
    }
  },
  methods: {
    gotoProfile() {
      this.$refs.dropdown.hide();
      this.$router.push('/profile').catch(()=>{});
    },
    gotoMyEvents() {
      this.$refs.dropdown.hide();
      this.$router.push('/my_events').catch(()=>{});
    },
    gotoMyTickets() {
      this.$refs.dropdown.hide();
      this.$router.push('/my_tickets').catch(()=>{});
    },
    gotoAddEvent() {
      this.$refs.dropdown.hide();
      if (this.$route.name === 'edit_event') {
        this.$router.push('/my_events/add_event')
      } else {
        this.$router.push('/add_event').catch(()=>{});
      }
    },
    doLogout() {
      if (confirm(this.$t('confirm_logout'))) {
        this.$store.commit('set', ['myId', '']);
        this.$store.commit('set', ['myInfo', null]);
        localStorage.setItem('eventboxMyId', '');
        localStorage.setItem('eventboxMyInfo', '');
        window.location.reload();
      }
    }
  }
};
</script>
